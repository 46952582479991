import React from "react";
import ReactGA from "react-ga4";
import { Row, Col, Button, Container, UncontrolledTooltip} from "reactstrap";

const styles = {
  playerStyle: {
    width: '100%',
    height: '600px',
    borderRadius: '3px'
  },

  header1Style: {
    textAlign: 'center',
    fontSize: '42px',
    marginBottom: '32px'
  },

  beatStoreContainerStyle: {
    marginTop: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },

  iconStyle: {
    marginRight: '8px',
  },

  buttonStyle: {
    marginBottom: '12px',
  },

  licensingButtonStyle: {
    marginTop: '32px',
    marginBottom: '32px'
  },

  fadeTextStyle: (isShown) => ({
    color: '#ffc107',
    fontWeight: 'bold',
    marginTop: '12px',
    marginBottom: '24px',
    opacity: isShown ? 1 : 0,
    textAlign: 'center',
    transition: 'opacity 0.5s ease-in-out'
  }),

  columnStyle:{
    padding: '0'
  }
};

class BeatStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      areHandlersActive: true,
    };
  }

  // Helper method to detect mobile devices
  isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  handleMouseEnter = () => {
    if (this.state.areHandlersActive) {
      this.setState({ isShown: true });
    }
  };

  handleMouseLeave = () => {
    if (this.state.areHandlersActive) {
      this.setState({ isShown: false });
    }
  };

  handleButtonClick = () => {
    // Only show text if the device is mobile
    if (this.isMobile()) {
      this.setState({ isShown: true, areHandlersActive: false });
    }
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://pagebuilder-cdn.soundee.com/analytics.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    const { isShown } = this.state;

    return (
      <Container>
        <h1 style={styles.header1Style}>Instant Beat Store</h1>
        <Row className="justify-content-md-center">
          <Col className="text-center" md="4" style={styles.columnStyle}>
            <Button
              className="btn btn-round"
              color="success"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={this.handleButtonClick}
              role="button"
              size="lg"
              style={styles.buttonStyle}
            >
              <i className="fa fa-tag" style={styles.iconStyle} />
              <b>BUY 2+2 FREE</b>
            </Button>
          </Col>
          <Col className="text-center" md="4" style={styles.columnStyle}>
            <Button
              className="btn btn-round"
              color="success"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={this.handleButtonClick}
              role="button"
              size="lg"
              style={styles.buttonStyle}
            >
              <i className="fa fa-tag" style={styles.iconStyle} />
              <b>BUY 3+3 FREE</b>
            </Button>
          </Col>
          <Col className="text-center" md="4" style={styles.columnStyle}>
            <Button
              className="btn btn-round"
              color="success"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onClick={this.handleButtonClick}
              role="button"
              size="lg"
              style={styles.buttonStyle}
            >
              <i className="fa fa-tag" style={styles.iconStyle} />
              <b>BUY 4+4 FREE</b>
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <p style={styles.fadeTextStyle(isShown)}>Bulk deals apply automatically</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <button
              className="btn btn-default btn-sm"
              id="tooltip1"
              type="button"
            >
              <i className="fa fa-info-circle" style={styles.iconStyle} />
              Looking for a specific beat?
            </button>
            <UncontrolledTooltip delay={0} target="tooltip1" placement="right">
              Use the search bar at the top of the player &nbsp;
              <i className="fa fa-search" style={styles.iconStyle} />
            </UncontrolledTooltip>
          </Col>
        </Row>

        <div style={styles.beatStoreContainerStyle} className="section section-beat-store">
          <iframe
            id="soundee_player"
            frameBorder="0"
            src="https://190.soundee.com"
            allow="autoplay"
            style={styles.playerStyle}
          ></iframe>
        </div>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn"
              color="danger"
              href="/licensing"
              role="button"
              size="lg"
              style={styles.licensingButtonStyle}
              onClick={() => ReactGA.event({
                category: 'Info',
                action: 'Click Homepage View Licensing Terms Button'
              })}
            >
              <i className="fa fa-cogs" style={styles.iconStyle} />
              View Licensing Terms
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BeatStore;
